import React from 'react'
import './Footer.css'

export default function Footer() {
    return (
        <div className='footer-main'>
            <div className='footer-image'>
                <img src={require ("../../../assets/Home/shape-bg.png")}/>
            </div>

        </div>
    )
}