import './App.css';
import PortfolioContainer from './Portfolio-Container/PortfolioContainer';

function App() {
  return (
    <div className="App">
       <PortfolioContainer/>
    </div>
  );
}

export default App;
